import React, {useState, useMemo} from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import media from 'styled-media-query';
import { AnimatePresence, motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import RevealWord from '../components/RevealWord';
import { Page, Menu } from '../components';

const Section = styled.section`
  width: 100%;
  position: relative;
  z-index: 3;
`

const Title = styled.h1`
  font-size: 60px;
  font-family: 'Krona One';
  text-transform: uppercase;
  font-weight: 100;
  margin-bottom: 60px;
  width: 100%;
  letter-spacing: 9px;
  text-align: center;
  margin-top: 164px;
  margin-bottom: 128px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  div {
    text-align: center;
  }

  ${media.lessThan('medium')`
    font-size: 32px;
    margin-bottom: 64px;
    margin-top: 120px;
  `}
`;


const Posts = styled.main`
  display: flex;
  flex-flow: column wrap;
  width: calc(100% - 200px - 10%);
  margin: 0;
  margin-left: auto;
  margin-right: 5%;
  padding-right: 10%;
  padding-left: 5%;

  ${media.lessThan('medium')`
    width: 100%;
    margin: 0 auto;
  `}
`;

const PostWrapper = styled(motion.div)`
  width: 50%; 
  max-width: 460px;
  margin-bottom: 64px;
  text-decoration: none;

  a {
    text-decoration: none;
  }

  :nth-child(even) {
    margin-left: auto;
    ${media.lessThan('medium')`
      margin-right: auto;
      margin-left: auto;
    `}
  }

  ${media.lessThan('medium')`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 120px;
  `}
`
const PostDate = styled(motion.div)`
  color: #74686E;
  font-size: 16px;
  font-family: 'Krona One';
  text-transform: uppercase;

  ${media.lessThan('medium')`
    font-size: 14px;
  `}
`
const PostTitle = styled(motion.h2)`
  font-family: 'Krona One';
  font-size: 36px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 600;
  margin: 20px 0;

  ${media.lessThan('medium')`
    font-size: 24px;
  `}
`
const PostCategories = styled(motion.div)`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
`
const Category = styled.div`
  font-size: 12px;
  text-transform: uppercase;

  :not(:first-of-type) {
    margin-left: 16px;
  }
`

const BackgroundImage = styled(motion.div)`
  width: 100%;
  height: 100vh;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;
  user-select: none;

  ${media.lessThan('medium')`
    .background-image, img {
      height: 100vh;
    }
  `}
`;

const Empty = styled.p`
  width: 100%;
  text-align: center;
  font-family: 'Krona One';
  font-size: 18px;
  text-transform: uppercase;
`

const Post = ({ post, index }) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <PostWrapper initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0, transition: { bounce: 0.2, delay: index * 0.1 } }} exit={{ opacity: 0, y: -10 }} onHoverStart={() => setIsHovered(true)} onHoverEnd={() => setIsHovered(false)}>
      <Link to={`/blog/${post.node.slug}`}>
        <PostDate animate={{ y: isHovered ? -10 : 0, transition: { type: 'spring', bounce: 0.1, delay: 0.1 } }}>{post.node.createdAt}</PostDate>
        <PostTitle animate={{ y: isHovered ? -10 : 0, transition: { type: 'spring', bounce: 0.1, delay: 0.25 } }}>{post.node.title}</PostTitle>
        <PostCategories animate={{ y: isHovered ? -10 : 0, transition: { type: 'spring', bounce: 0.1, delay: 0.35 } }}>
          {post.node.categories.map(category => (
            <Category>{category.title}</Category>
          ))}
        </PostCategories>
      </Link>
    </PostWrapper>
  )
}

const BlogPage = ({data}) => {
  const imageData = data.background.childImageSharp.fluid;

  const [filter, setFilter] = useState(undefined);
  const [categories] = useState(data.allContentfulBlogCategories.nodes || [])

  const filterPosts = useMemo(() => {
    if (filter) {
      if (filter === 'Todos') return data?.allContentfulBlog?.edges;

      return data?.allContentfulBlog?.edges.filter((item) => {
        return !!item.node.categories.find((i) => i.title === filter);
      });
    }
    return data?.allContentfulBlog?.edges.map((item) => item);
  }, [filter]);


  const filters = useMemo(() => {
    const allCategories = categories.map((node, index) => ({
      id: index + 1,
      title: node.title,
      href: undefined,
      onClick: () => setFilter(node.title),
    }));

    return [
      {
        id: 0,
        title: 'Todos',
        onClick: () => setFilter('Todos'),
      },
      ...allCategories,
    ];
  }, [categories, filter]);

  return (
    <Page title="Blog" description="Página cases do site Lab721">
      <BackgroundImage>
        <Img
          fluid={imageData}
          className="background-image"
          alt="Imagem de fundo da página manifesto"
        />
      </BackgroundImage>
      <Section>
        <Menu items={filters} />

        <Title>
          <RevealWord delay={1}>Blog</RevealWord>
        </Title>

        {filterPosts.length > 0 ? (
          <Posts>
            <AnimatePresence exitBeforeEnter key={filter}>
              {filterPosts.map((post, index) => (
                <Post key={`${filter}_${index}_${post.node.id}`} post={post} index={index} />
              ))}
            </AnimatePresence>
          </Posts>
        ) : (
          <Empty>Nenhum post cadastrado</Empty>
        )}

      </Section>
    </Page>
  )
}


export const pageQuery = graphql`
  query {
    background: file(relativePath: { eq: "manifesto-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 2900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allContentfulBlogCategories {
      nodes {
        title
      }
    }

    allContentfulBlog {
      edges {
        node {
          categories {
            title
          }
          title
          slug
          createdAt(formatString: "DD MMM")
        }
      }
    }
  }
`;

export default BlogPage
